@import "simplebar/dist/simplebar.css";
@import "highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

:root {
  --mdc-filled-button-container-color: #c86f35;
}


.missingInput {
  color: #f80000 !important;
  border: 1px #f80000 solid;
}

.invalidForm {
  border: 1px #f80000 solid;
}
.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  line-height: 1.3;
  border: 1px solid #dee5e7;
}
.badge, .label {
  font-weight: bold;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}
.label-primary {
  color: #7266ba;
  border-color: #7266ba;
}
.inline {
  display: inline-block !important;
}

.badge, .label {
  font-size: 11px;
  //margin-right: 5px;
  //margin-bottom: 5px;
}

.label-katalogove-cislo {
  color: #57b330;
  text-shadow: none;
  line-height: 1.5;
  white-space: nowrap;
  border-color: #57b330;
}

.label-client {
  color: #3cd9a4;
  text-shadow: none;
  line-height: 1.5;
  white-space: nowrap;
  border-color: #3cd9a4;
}

.label-client-preferredBidderNumber {
  color: #9659cd;
  text-shadow: none;
  line-height: 1.5;
  white-space: nowrap;
  border-color: #9659cd;
}


.label-shared {
  color: #158ca6;
  text-shadow: none;
  line-height: 1.5;
  height: 23px;
  white-space: nowrap;
  border-color: #158ca6;
}

.label-limitni-cislo {
  color: rgba(0, 128, 255, 0.7);
  text-shadow: none;
  line-height: 1.5;
  white-space: nowrap;
  border-color: rgba(0, 128, 255, 0.7);
}


/*
  *  Gallery
 */

.full-screen-dialog {
  --mdc-dialog-container-color: transparent;
  pointer-events: none;
}
.p-galleria {
  .p-galleria-content {
    width: calc(100vw - 2rem);
    p-galleriathumbnails {
      pointer-events: all;
    }
  }
  .p-galleria-item {
    > .p-galleria-item-close-button {
      position: fixed;
      top: 1rem;
      right: 1rem;
      z-index: 2;
      padding: .5rem;
      font-size: 2rem;
      color: white;
      filter: drop-shadow(0 0 2px rgba(0, 0, 0, 1));
      opacity: .5;
      transition: opacity .5s ease;
      &:hover {
        opacity: 1;
      }
    }
    > .p-galleria-item-img-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      > img {
        cursor: zoom-in;
        pointer-events: all;
      }
    &:has(>.p-galleria-item-img-zoom.enabled) {
        > img {
          cursor: none;
        }
      }
      .p-galleria-item-img-zoom {
        position: absolute;
        aspect-ratio: 1;
        background-repeat: no-repeat;
        background-image: var(--page-background);
        pointer-events: none;
        transform: translate(-50%, -50%);
        opacity: 0;
        border-radius: 9999px 9999px;
        transition: opacity .6s ease-out;
        z-index: 2;
        filter: drop-shadow(0 0 2rem rgba(0, 0, 0, 1));

        &.enabled {
          transition: opacity .6s ease-in;
          opacity: 1;
          box-shadow: calc(var(--zoom-shadow-size) / 2) calc(var(--zoom-shadow-size) / 2) var(--zoom-shadow-size) 0 rgba(0,0,0, 0.5);
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            aspect-ratio: 1;
            backdrop-filter: hue-rotate(18deg) blur(4px) brightness(1.05);
            border-radius: 9999px 9999px;
            mask-image: url('/assets/img/zoom/zoom-mask.svg');
            mask-repeat: no-repeat;
            mask-size: 110%;
            mask-position: center;
            mix-blend-mode: initial;
            mix-blend-mode: screen;

            // Safari
            @supports (-webkit-hyphens:none) {
              mix-blend-mode: normal;
            }

            // Firefox
            @supports (-moz-appearance:none) {
              mix-blend-mode: normal;
            }
          }

          &::after {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            aspect-ratio: 1;
            background-image: url('/assets/img/zoom/zoom-glass.png');
            background-repeat: no-repeat;
            background-size: calc(100% + 5px);
            background-position: center;
          }
        }
      }
    }
  }
  .p-galleria-item-prev,
  .p-galleria-item-next {
    z-index: 1;
  }
  .p-galleria-item-container {
    button {
      pointer-events: all;
    }
  }
  .p-galleria-thumbnail-container {
    background: transparent;
    padding-bottom: 0;
    &:has(.p-galleria-thumbnail-item:first-child:last-child) {
      display: none;
    }
    .p-galleria-thumbnail-item {
      opacity: .9;
      transition: all .3s ease;
      margin-top: 1rem;
      margin-bottom: 1rem;
      cursor: default;
      &.p-galleria-thumbnail-item {
        > .p-galleria-thumbnail-item-content {
          cursor: pointer;
        }
      }
      &.p-galleria-thumbnail-item-current {
        transform: scale(1.2);
        filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.5));
      }
    }
  }
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.8);
  & + .cdk-global-overlay-wrapper {
    .mat-mdc-dialog-surface {
      box-shadow: none !important;
      border-radius: 0;
      overflow: visible;
    }
  }
}

/*
  *  Contract Preview
 */

.contract-preview h1 {
  text-align: center  !important;
  display: block;
  font-weight: bold  !important;
}

.contract-preview h2 {
  text-align: center !important;
  font-weight: bold  !important;
  display:block;
  font-size: 30px;
}

.contract-preview .contract-table {

}
.contract-preview .contract-table tr {
  border: 1px solid #000;
}
.contract-preview .contract-table td {
  border: 1px solid #000;
}



:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}

.p-timeline-event-marker {
  background-color: rgb(96, 103, 220);
}


/* Chrome, Safari, Edge, Opera */
.hideInputArrows input::-webkit-outer-spin-button,
.hideInputArrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hideInputArrows input[type=number] {
  -moz-appearance: textfield;
}
